.animation-toolbar {
  left: 0;
  padding: 30px;
  position: fixed;
  top: 0;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: auto,
}

.moving-car {
  height: auto;
  margin: 0 auto;
  width: 50%;
}
.moving-car__car-body {
  animation: 5s infinite carMovement;
  position: relative;
}
.moving-car__movement-stripe {
  animation: 1s infinite stripeMovement;
}
.moving-car__movement-stripe:nth-child(2n) {
  animation-delay: 0.25s;
}
.moving-car__movement-stripe:nth-child(3n) {
  animation-delay: 0.5s;
}
.moving-car__road {
  animation: 4s infinite linear roadMovement;
}
.moving-car__check-bottom,
.moving-car__check-side {
  height: 0;
}
.moving-car__wheel-deflate {
  display: none;
}
.moving-car__cloud {
  opacity: 0;
}
.moving-car.moving-car--complete .moving-car__check-bottom {
  animation: 0.2s 0.3s forwards linear checkBottomCreation;
}
.moving-car.moving-car--complete .moving-car__check-side {
  animation: 0.2s 0.49s forwards linear checkSideCreation;
}
.moving-car.moving-car--complete .moving-car__car-body {
  animation: 2s forwards carMoveOff;
}
.moving-car.moving-car--complete .moving-car__movement-stripe {
  display: none;
}
.moving-car.moving-car--complete .moving-car__road {
  animation: 2s forwards roadMovement;
}
.moving-car.moving-car--error .moving-car__car-body {
  animation: 1.25s forwards carMoveBack;
}
.moving-car.moving-car--error .moving-car__movement-stripe {
  animation: 1.25s forwards linear roadMovement;
}
.moving-car.moving-car--error .moving-car__road {
  animation: 1.25s backwards roadMovement;
}
.moving-car.moving-car--error .moving-car__car-wheel {
  animation: 2s forwards tireShrink;
  animation-delay: 1s;
}
.moving-car.moving-car--error .moving-car__wheel-deflate {
  animation: 2s forwards tireDeflate;
  display: initial;
  width: 0;
}
.moving-car.moving-car--error .moving-car__hood {
  animation: 0.25s forwards hoodUp;
  animation-delay: 1.5s;
}
.moving-car.moving-car--error .moving-car__cloud:nth-child(1n) {
  animation: 2.75s infinite cloudsUpOne;
  animation-delay: 1.5s;
  transform: translateY(95px);
}
.moving-car.moving-car--error .moving-car__cloud:nth-child(2n) {
  animation: 2.75s infinite cloudsUpOne;
  animation-delay: 2.75s;
  transform: translateY(95px);
}
.moving-car.moving-car--error .moving-car__cloud:nth-child(3n) {
  animation: 3.5s infinite cloudsUpTwo;
  animation-delay: 3.5s;
  transform: translateY(95px);
}
.moving-car.moving-car--error .moving-car__cloud:nth-child(4n) {
  animation: 3.5s infinite cloudsUpTwo;
  animation-delay: 4.25s;
  transform: translateY(95px);
}

/**
  * Loading animations
  **/
@keyframes carMovement {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes stripeMovement {
  0% {
    transform: translateX(35px);
  }
  100% {
    transform: translateX(-110px);
  }
}
@keyframes roadMovement {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-265px);
  }
}
/**
  * Complete animations
  **/
@keyframes checkBottomCreation {
  0% {
    height: 0;
  }
  100% {
    height: 65px;
  }
}
@keyframes checkSideCreation {
  0% {
    height: 0;
  }
  100% {
    height: 101px;
  }
}
@keyframes carMoveOff {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(120%);
  }
}
@keyframes carMoveBack {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-9%, 6px, 0);
  }
}
@keyframes tireShrink {
  0% {
    transform: translateY(0) scaleY(100%);
  }
  100% {
    transform: translateY(28px) scaleY(80%);
  }
}
@keyframes tireDeflate {
  0% {
    transform: translateX(15px);
    width: 0;
  }
  100% {
    transform: translateX(0px);
    width: 37px;
  }
}
@keyframes hoodUp {
  0% {
    transform: rotate(0) translate3d(0, 0, 0);
  }
  25% {
    transform: rotate(-10deg) translate3d(-18px, 31px, 0);
  }
  50% {
    transform: rotate(-20deg) translate3d(-47px, 58px, 0);
  }
  100% {
    transform: rotate(-32deg) translate3d(-82px, 84px, 0);
  }
}
@keyframes cloudsUpOne {
  0% {
    opacity: 0;
    transform: scaleY(70%) translateX(192px) translateY(135px);
  }
  80% {
    opacity: 0.9;
    transform: scaleY(100%) translateX(205px) translateY(62px);
  }
  100% {
    opacity: 0;
    transform: scaleY(100%) translateX(204px) translateY(58px);
  }
}
@keyframes cloudsUpTwo {
  0% {
    opacity: 0;
    transform: scaleY(70%) translateX(206px) translateY(135px);
  }
  60% {
    opacity: 0.9;
    transform: scaleY(100%) translateX(212px) translateY(72px);
  }
  100% {
    opacity: 0;
    transform: scaleY(100%) translateX(211px) translateY(68px);
  }
}
