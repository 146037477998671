.blink__box {
  animation: blink 3.5s infinite alternate;
}

@keyframes blink {
  0% {
    background-color: #ffa000;
    transform: scale(1);
  }
  50% {
    background-color: #ffc107;
    transform: scale(1.03);
  }
  100% {
    background-color: #ffa000;
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.9;
  }
  50% {
    transform: scale(1.02);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0.9;
  }
}

.pulse-alert {
  animation: pulse 1.5s infinite;
}
